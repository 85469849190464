.App {
    font-family: sans-serif;
    text-align: center;
  }
  
  .fade-in-section {
    opacity: 0;
    transform: translateY(20vh);
    visibility: hidden;
    transition: opacity 1s ease-in-out, transform 1s ease-in-out,
      visibility 2ms ease-in-out;
      /* 1200 */
    will-change: opacity, transform, visibility;
  }
  .fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
  
  /* Just for presentational purposes, please disregard in your own implementation */
  .App {
    max-width: 100%;
    padding: 1em;
  }
  
  .box {
    padding: 50px;
    margin: 20px 0;
  }
  
  .box span {
    background-color: rgba(255, 255, 255, 0.5);
    display: inline-block;
    padding: 5px;
    border-radius: 3px;
  }
  
  html {
    box-sizing: border-box;
  }
  * {
    box-sizing: inherit;
  }
  